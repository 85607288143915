/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'dev'

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyCCOSTQhoLZgeWTl3xRz_sSRIiPTfOGW5Q',
  authDomain: 'digital-rolodex.firebaseapp.com',
  databaseURL: 'https://digital-rolodex.firebaseio.com',
  projectId: 'digital-rolodex',
  storageBucket: 'digital-rolodex.appspot.com'
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
}

export default {
  env,
  firebase,
  reduxFirebase
}
